<template>
  <div class="row no-gutters mt-5 alina-form p-5">
    <div class="col">
      (c) localhost
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {}
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
